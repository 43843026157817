export const commonSortingMixin = {
    data() {
      return {
      };
    },
    computed:{
        getSortingLabel(){
            return key => {
              let iconClass = 'text-muted ri-arrow-up-line';
              if(this.sortBy.sort_column != key) return `<span class='ms-2 ${iconClass}'></span>`;
              iconClass = this.sortBy.sort_direction == 'desc' ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
              return `<span class='ms-2 ${iconClass}'></span>`
            }
        }
    },
    methods: {
        setSortOrder(column) {
            if (this.sortBy.sort_column === column.key) {
                this.sortBy.sort_direction = this.sortBy.sort_direction === 'desc' ? 'asc' : 'desc';
            } else {
                this.sortBy.sort_column = column.key;
                this.sortBy.sort_direction = 'desc';
            }
            this.fetchItems();
        },
    },
};