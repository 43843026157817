export const commonPaginationMixin = {
    data() {
      return {
        pageOptions: [5, 10, 25, 50, 100, 500, 1000],
        perPage: 25
      };
    },
    computed:{
        currentPage: {
            get() {
              return this.pageData.current_page
            },
            set(val) {
              if(!this.pageData.current_page || this.pageData.current_page == val) return
              this.fetchItems(val)
            }
          },
          itemsPerPage: {
            get() {
              return +this.pageData.per_page || this.perPage
            },
            set(val) {
              this.fetchItems(1, val)
            }
          },
          paginationInfo(){
            if(!this.pageData.total) return '0 - 0 of 0'
            return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
            - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
            ? this.currentPage * this.itemsPerPage : this.pageData.total}
            of ${this.pageData.total}`
        },
    },
};